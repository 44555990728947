var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('section',{attrs:{"id":"income-service"}},[_c('b-row',[_c('b-col',{attrs:{"lg":"3"}},[_c('b-card-actions',{attrs:{"action-collapse":"","title":"Φίλτρα"}},[_c('b-form-group',{attrs:{"label":"Από","label-for":"start-date"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"start-date","config":{
					allowInput: true,
                  altInput: true,
                  altFormat: 'd-m-Y',
                  dateFormat: 'Y-m-d',
                },"value":null},model:{value:(_vm.form.from),callback:function ($$v) {_vm.$set(_vm.form, "from", $$v)},expression:"form.from"}})],1),_c('b-form-group',{attrs:{"label":"Έως","label-for":"end-date"}},[_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"end-date","config":{
					allowInput: true,
                  altInput: true,
                  altFormat: 'd-m-Y',
                  dateFormat: 'Y-m-d',
                },"value":null},model:{value:(_vm.form.to),callback:function ($$v) {_vm.$set(_vm.form, "to", $$v)},expression:"form.to"}})],1),_c('b-form-group',{attrs:{"label":_vm.$t('generic.services')}},[_c('v-select',{attrs:{"options":_vm.getSettings,"reduce":function (val) { return val.id; },"input-id":"project-category","label":"title","placehoder":"Επιλέξτε..."},scopedSlots:_vm._u([{key:"no-options",fn:function(){return [_vm._v(_vm._s(_vm.$t('errors.no-matching')))]},proxy:true}]),model:{value:(_vm.form.categoryId),callback:function ($$v) {_vm.$set(_vm.form, "categoryId", $$v)},expression:"form.categoryId"}})],1),_c('b-button',{staticClass:"mt-2",attrs:{"block":"","variant":"outline-primary"},on:{"click":_vm.generateReport}},[_c('span',[_vm._v(_vm._s(_vm.$t("generic.view-results")))])]),_c('b-button',{attrs:{"block":"","variant":"flat-warning"},on:{"click":_vm.reset}},[_vm._v(" Καθαρισμός ")])],1)],1),_c('b-col',{attrs:{"lg":"9"}},[_c('b-card',{attrs:{"no-body":""}},[(_vm.showPdf)?_c('b-row',{staticStyle:{"height":"100vh"}},[_c('vue-pdf-app',{staticClass:"w-100 h-100",attrs:{"pdf":_vm.$store.getters.getIncomeService}})],1):_vm._e()],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }